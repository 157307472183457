/*** FONT FAMILIES AND FONT SIZES ***/
/*** BOOTSTRAP OPTIONS ***/
/*** SEMANTIC COLOR SCHEME ***/
/*** OTHER BOOTSTRAP VARIABLES ***/
/*** CUSTOM DSPACE VARIABLES ***/
/*** MISC ***/
.ds-full-screen-loader {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}